var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.baseClass + '-type-page'},[_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-type-games-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-type-games-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('div',{class:_vm.baseClass + '-container-title'},[_c('div',{staticClass:"container"},[_vm._v("Top Moto "+_vm._s(_vm.typeName)+" Games")])]),_c('div',{class:_vm.baseClass + '-type-games-container-list'},_vm._l((_vm.isPid ? _vm.typeGames.slice(0, 5) : _vm.typeGames),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"new"}})}),1),_c('div',{class:_vm.baseClass + '-type-games-container-pagination-container'},[_c('Pagination',{attrs:{"page":_vm.page,"size":_vm.pageSize,"length":_vm.typeGamesAll.length},on:{"change":_vm.changePage}})],1)])])]),_c('div',{class:_vm.baseClass + '-ad-box'},[_c('div',{class:_vm.baseClass + '-ad-box-title'},[_vm._v("Advertisement")]),_c('div',{class:_vm.baseClass + '-ad-box-content'})]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass + '-type-container'},[_c('div',{class:_vm.baseClass + '-type-container-title'},[_vm._v("Game Series")]),_c('div',{class:_vm.baseClass + '-type-container-list'},_vm._l((_vm.$store.state.typeList),function(item,index){return _c('Type',{key:index,attrs:{"item":item}})}),1)])]),_c('div',{class:_vm.baseClass + '-row'},[_c('div',{class:_vm.baseClass +
        '-all-container' +
        ' ' +
        _vm.baseClass +
        '-linear-border-container'},[_c('div',{class:_vm.baseClass +
          '-all-content' +
          ' ' +
          _vm.baseClass +
          '-linear-border-content'},[_c('div',{class:_vm.baseClass + '-container-title'},[_c('div',{staticClass:"container"},[_vm._v("Moto "+_vm._s(_vm.typeName)+" Games")])]),_c('div',{class:_vm.baseClass + '-all-container-list'},_vm._l((_vm.typeGamesAll),function(item){return _c('Game',{key:item.id,attrs:{"item":item,"type":"all"}})}),1)])])]),_c('div',{class:_vm.baseClass + '-row'},[_c('Tips')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }