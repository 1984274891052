<template>
  <div :class="baseClass + '-type-page'">
    <div :class="baseClass + '-row'">
      <div
        :class="
          baseClass +
          '-type-games-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        "
      >
        <div
          :class="
            baseClass +
            '-type-games-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          "
        >
          <div :class="baseClass + '-container-title'">
            <div class="container">Top Moto {{ typeName }} Games</div>
          </div>
          <div :class="baseClass + '-type-games-container-list'">
            <Game
              v-for="item of isPid ? typeGames.slice(0, 5) : typeGames"
              :item="item"
              :key="item.id"
              type="new"
            />
          </div>
          <div
            :class="baseClass + '-type-games-container-pagination-container'"
          >
            <Pagination
              :page="page"
              :size="pageSize"
              @change="changePage"
              :length="typeGamesAll.length"
            />
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-ad-box'">
      <div :class="baseClass + '-ad-box-title'">Advertisement</div>
      <div :class="baseClass + '-ad-box-content'"></div>
    </div>
    <div :class="baseClass + '-row'">
      <div :class="baseClass + '-type-container'">
        <div :class="baseClass + '-type-container-title'">Game Series</div>
        <div :class="baseClass + '-type-container-list'">
          <Type
            v-for="(item, index) of $store.state.typeList"
            :key="index"
            :item="item"
          />
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <div
        :class="
          baseClass +
          '-all-container' +
          ' ' +
          baseClass +
          '-linear-border-container'
        "
      >
        <div
          :class="
            baseClass +
            '-all-content' +
            ' ' +
            baseClass +
            '-linear-border-content'
          "
        >
          <div :class="baseClass + '-container-title'">
            <div class="container">Moto {{ typeName }} Games</div>
          </div>
          <div :class="baseClass + '-all-container-list'">
            <Game
              v-for="item of typeGamesAll"
              :item="item"
              :key="item.id"
              type="all"
            />
          </div>
        </div>
      </div>
    </div>
    <div :class="baseClass + '-row'">
      <Tips />
    </div>
  </div>
</template>

<script>
import Game from "@/components/GameItem.vue";
import Type from "@/components/TypeItem.vue";
import Tips from "@/components/Tips.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  components: { Game, Type, Tips, Pagination },
  data() {
    //Arcade
    return {
      typeName: "all",
      typeGames: [],
      typeGamesAll: [],
      page: 0,
      pageSize: 6,
    };
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.params.type) {
        this.typeName = this.$route.params.type;
      }
      this.getTypeGames();
      this.typeGamesAll = [].concat(
        this.$store.state.gameTypeHash[this.typeName]
      );
    },
    getTypeGames() {
      this.typeGames = []
        .concat(this.$store.state.gameTypeHash[this.typeName])
        .splice(this.page * this.pageSize, this.pageSize);
    },
    changePage(type) {
      if (type == "up") {
        if ((this.page + 1) * this.pageSize >= this.typeGamesAll.length) {
          return false;
        } else {
          this.page++;
        }
      } else {
        if (this.page !== 0) {
          this.page--;
        } else {
          return false;
        }
      }
      this.getTypeGames();
    },
  },
};
</script>

<style lang="scss">
.#{$baseClass}-type-page {
  .#{$baseClass}-row {
    &:nth-child(1) {
      padding-top: 76px;
    }
  }
  .#{$baseClass}-container {
    &-title {
      width: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
      top: -44px;
      .container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        height: 88px;
        background: linear-gradient(180deg, #fed537 0%, #ff6b94 100%);
        box-shadow: 0px 4px 16px 0px rgba(255, 114, 142, 0.3);
        border-radius: 8px;
        font-size: 36px;
        font-family: Arial-BoldMT, Arial;
        font-weight: normal;
        color: #ffffff;
        line-height: 42px;
        text-shadow: 0px 4px 16px rgba(255, 114, 142, 0.3), 0px 4px 0px #db4d01;
      }
    }
  }
  .#{$baseClass}-type-games-container {
    width: 1360px;
    height: 386px;
    .#{$baseClass}-type-games-content {
      position: relative;
      background: #2b89ff;
      padding-bottom: 54px;
    }
    &-list {
      height: 100%;
      width: 100%;
      padding: 80px 20px 0;
      display: flex;
      flex-wrap: wrap;
    }
    &-pagination-container {
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .#{$baseClass}-type-page {
    .#{$baseClass}-row {
      &:nth-child(1) {
        padding-top: 36px;
      }
    }
    .#{$baseClass}-container {
      &-title {
        top: -24px;
        .container {
          padding: 0 52px;
          height: 48px;
          font-size: 24px;
          line-height: 28px;
          text-shadow: 0px 2px 0px #db4d01;
        }
      }
    }
    .#{$baseClass}-type-games-container {
      width: 765px;
      height: 236px;
      .#{$baseClass}-type-games-content {
        position: relative;
        background: #2b89ff;
        padding-bottom: 20px;
      }
      &-list {
        height: 100%;
        width: 100%;
        padding: 42px 17px 0;
      }
    }
  }
}
</style>
